
h1 {
  font-size: 2em;
}

.product-title {
  font-weight: bold;
  font-size: 1.2em;
}

.product-id {
  font-size: .8em;
  margin: 1em 0;
}

.homeTitle {
  font-style: normal;
  font-size: 32px;
  margin-left: 15px;
  margin-top: 16px;
}

.hero-body {
  padding-top: 0rem;
  padding-left: 1.5rem;
}

.maxbutton {
  position: relative;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  width: 160px;
  height: 50px;
  border: 2px solid #505ac7;
  border-radius: 4px 4px 4px 4px;
  background-color: #505ac7;
  box-shadow: 0px 0px 2px 0px #333333;
}
.maxbutton:hover {
  border-color: #505ac7;
  background-color: white;
  box-shadow: 0px 0px 2px 0px #333333;
}
.maxbutton .mb-text {
  color: #ffffff;
  font-size: 15px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  box-sizing: border-box;
  display: block;
  background-color: unset;
  position: relative;
  padding: 15px 0px 0px 0px;
}
.maxbutton:hover .mb-text {
  color: #505ac7;
}

.recoBackground {
  background-image: -webkit-linear-gradient(left, #0d0924, #232250, #3f3980, #6150b2, #8768e6);
  background-image: linear-gradient(to right, #0d0924, #232250, #3f3980, #6150b2, #8768e6);
  color: white;
}

.brandName {
  font-size: 78px;
}

.cta-btn {
  background-color: #845EC2; 
  color: white;
}

.cta-btn:hover {
  background-color: rgb(89 63 132); 
  color: white;
}

.recoChild {
  background-color: red;
  color: black;
  height: 350px;
  width: 40vw;
  border: '1px solid black'; 
  list-style: "none";
  margin: 20px;
  padding: 15px;
  border-radius: 30px; 
  box-shadow: 3px 5px 8px -1px #000000;
}

.recoChild:first-child {
  width: 50vw;
  background-color: whitesmoke;
}

.product-edit-icon {
  opacity: .7;
  position: absolute;
  right: 2.3rem;
  top: .4rem;
}

.product-edit-icon:hover {
  opacity: 1;
}

.navbar, .navbar-end, .navbar-menu, .navbar-start {
  -webkit-align-items: stretch !important;
          align-items: stretch !important;
  display: -webkit-flex !important;
  display: flex !important;
}

.navbar-menu {
  -webkit-flex-grow: 1 !important;
          flex-grow: 1 !important;
  -webkit-flex-shrink: 0 !important;
          flex-shrink: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.navbar-item {
  display: -webkit-flex !important;
  display: flex !important;
}

.navbar-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
  margin-left: auto !important;
}

.features {
  padding-bottom: 2em;
}

body {
  visibility: visible !important;
}

.footer {
  padding: 1rem 1.5rem 1rem;
}

.auth form {
  margin-top: 1em;
  max-width: 50%;
}

.buttons {
  margin-left: 1em;
}
